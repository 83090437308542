// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Gatsby from "gatsby";
import * as Gradient from "../../../utils/Gradient.bs.js";

function PostItem(Props) {
  var title = Props.title;
  var description = Props.description;
  var slug = Props.slug;
  var match = Gradient.getColors(title);
  return React.createElement("article", {
              className: "mt-12 text-gray-900 hover:text-transparent"
            }, React.createElement(Gatsby.Link, {
                  to: "/posts/" + slug,
                  children: null
                }, React.createElement("h3", {
                      className: "text-current font-bold text-3xl mb-4 transition-colors duration-300"
                    }, React.createElement("span", {
                          className: [
                              "inline-block",
                              "bg-clip-text",
                              "bg-gradient-to-r",
                              "from-" + match[0] + "-600",
                              "to-" + match[1] + "-600"
                            ].join(" ")
                        }, title)), React.createElement("p", {
                      className: "text-gray-700 text-base"
                    }, description, React.createElement("span", {
                          className: "font-bold block underline whitespace-nowrap mt-2"
                        }, "더 읽기"))));
}

var make = PostItem;

export {
  make ,
  
}
/* react Not a pure module */
