// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SEO from "./SEO.bs.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Nullable from "../utils/Nullable.bs.js";

var Raw = {};

var query = (graphql`
  query HeadMetadata  {
    site  {
      siteMetadata  {
        title
        description
        siteUrl
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.site;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.siteMetadata;
    var tmp$1;
    if (value$2 == null) {
      tmp$1 = undefined;
    } else {
      var value$3 = value$2.title;
      var value$4 = value$2.description;
      var value$5 = value$2.siteUrl;
      tmp$1 = {
        title: !(value$3 == null) ? value$3 : undefined,
        description: !(value$4 == null) ? value$4 : undefined,
        siteUrl: !(value$5 == null) ? value$5 : undefined
      };
    }
    tmp = {
      siteMetadata: tmp$1
    };
  }
  return {
          site: tmp
        };
}

function serialize(value) {
  var value$1 = value.site;
  var site;
  if (value$1 !== undefined) {
    var value$2 = value$1.siteMetadata;
    var siteMetadata;
    if (value$2 !== undefined) {
      var value$3 = value$2.siteUrl;
      var siteUrl = value$3 !== undefined ? value$3 : null;
      var value$4 = value$2.description;
      var description = value$4 !== undefined ? value$4 : null;
      var value$5 = value$2.title;
      var title = value$5 !== undefined ? value$5 : null;
      siteMetadata = {
        title: title,
        description: description,
        siteUrl: siteUrl
      };
    } else {
      siteMetadata = null;
    }
    site = {
      siteMetadata: siteMetadata
    };
  } else {
    site = null;
  }
  return {
          site: site
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var HeadMetadata = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function Head(Props) {
  var data = Gatsby.useStaticQuery(query);
  var meta = Nullable.getExn(Nullable.flatMap(data.site, (function (site) {
              return site.siteMetadata;
            })));
  var title = Nullable.getExn(meta.title);
  var description = Nullable.getExn(meta.description);
  var siteUrl = Nullable.getExn(meta.siteUrl);
  return React.createElement(SEO.make, {
              title: title,
              description: description,
              url: siteUrl
            });
}

var make = Head;

var $$default = Head;

export {
  HeadMetadata ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
