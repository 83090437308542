// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Head from "../../Head.bs.js";
import * as React from "react";
import * as Footer from "../../Footer.bs.js";
import * as Gatsby from "gatsby";
import * as Nullable from "../../../utils/Nullable.bs.js";
import * as PostItem from "./PostItem.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NavigationBar from "../../NavigationBar.bs.js";

var Raw = {};

var query = (graphql`
  query PostList  {
    allMarkdownRemark(sort: {order: [DESC], fields: [frontmatter___createdAt]})  {
      nodes  {
        id
        excerpt(pruneLength: 240)
        fields  {
          slug
        }
        frontmatter  {
          title
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.allMarkdownRemark;
  var value$2 = value$1.nodes;
  return {
          allMarkdownRemark: {
            nodes: value$2.map(function (value) {
                  var value$1 = value.excerpt;
                  var value$2 = value.fields;
                  var tmp;
                  if (value$2 == null) {
                    tmp = undefined;
                  } else {
                    var value$3 = value$2.slug;
                    tmp = {
                      slug: !(value$3 == null) ? value$3 : undefined
                    };
                  }
                  var value$4 = value.frontmatter;
                  var tmp$1;
                  if (value$4 == null) {
                    tmp$1 = undefined;
                  } else {
                    var value$5 = value$4.title;
                    var value$6 = value$4.description;
                    var value$7 = value$4.createdAt;
                    var value$8 = value$4.updatedAt;
                    tmp$1 = {
                      title: !(value$5 == null) ? value$5 : undefined,
                      description: !(value$6 == null) ? value$6 : undefined,
                      createdAt: !(value$7 == null) ? Caml_option.some(value$7) : undefined,
                      updatedAt: !(value$8 == null) ? Caml_option.some(value$8) : undefined
                    };
                  }
                  return {
                          id: value.id,
                          excerpt: !(value$1 == null) ? value$1 : undefined,
                          fields: tmp,
                          frontmatter: tmp$1
                        };
                })
          }
        };
}

function serialize(value) {
  var value$1 = value.allMarkdownRemark;
  var value$2 = value$1.nodes;
  var nodes = value$2.map(function (value) {
        var value$1 = value.frontmatter;
        var frontmatter;
        if (value$1 !== undefined) {
          var value$2 = value$1.updatedAt;
          var updatedAt = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
          var value$3 = value$1.createdAt;
          var createdAt = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
          var value$4 = value$1.description;
          var description = value$4 !== undefined ? value$4 : null;
          var value$5 = value$1.title;
          var title = value$5 !== undefined ? value$5 : null;
          frontmatter = {
            title: title,
            description: description,
            createdAt: createdAt,
            updatedAt: updatedAt
          };
        } else {
          frontmatter = null;
        }
        var value$6 = value.fields;
        var fields;
        if (value$6 !== undefined) {
          var value$7 = value$6.slug;
          var slug = value$7 !== undefined ? value$7 : null;
          fields = {
            slug: slug
          };
        } else {
          fields = null;
        }
        var value$8 = value.excerpt;
        var excerpt = value$8 !== undefined ? value$8 : null;
        var value$9 = value.id;
        return {
                id: value$9,
                excerpt: excerpt,
                fields: fields,
                frontmatter: frontmatter
              };
      });
  var allMarkdownRemark = {
    nodes: nodes
  };
  return {
          allMarkdownRemark: allMarkdownRemark
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var PostList = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

function Main(Props) {
  var data = Gatsby.useStaticQuery(query);
  return React.createElement(React.Fragment, undefined, React.createElement(Head.make, {}), React.createElement(NavigationBar.make, {}), React.createElement("main", {
                  className: "py-4"
                }, React.createElement("div", {
                      className: "mb-12 max-w-2xl mx-auto px-4"
                    }, Belt_Array.map(data.allMarkdownRemark.nodes, (function (node) {
                            var frontmatter = Nullable.getExn(node.frontmatter);
                            var slug = Nullable.getExn(Nullable.flatMap(node.fields, (function (field) {
                                        return field.slug;
                                      })));
                            var description = Caml_option.nullable_to_opt(frontmatter.description) !== undefined ? Nullable.getExn(frontmatter.description) : Nullable.getExn(node.excerpt);
                            return React.createElement(PostItem.make, {
                                        title: Nullable.getExn(frontmatter.title),
                                        description: description,
                                        slug: slug,
                                        key: node.id
                                      });
                          })))), React.createElement(Footer.make, {}));
}

var make = Main;

var $$default = Main;

export {
  PostList ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
